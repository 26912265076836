.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #131028; /* Arka plan rengi */
}

.form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px; /* Yumuşak köşeler */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%; /* Formun genişliğini ayarlayın */
  max-width: 400px; /* Maksimum form genişliği */
  text-align: center;
  background-color: #fff; /* Form arka plan rengi */
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  text-align: left;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input {
  width: 100%;
  padding: 12px; /* ComboBox'ların ve metinlerin boyutunu ayarlayın */
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px; /* Yazı tipi boyutunu ayarlayın */
}

.input#kullaniciAdi {
  width: 93.5%; /* veya başka bir genişlik değeri belirleyin */
}

.button {
  background: #f27127; /* Button rengi */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px; /* Button boyutunu ayarlayın */
  font-size: 16px; /* Yazı tipi boyutunu ayarlayın */
  cursor: pointer;
}

.button:disabled {
  background: #ccc; /* Devre dışı buton rengi (gri) */
  cursor: not-allowed;
}

.notification {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
  color: #fff; /* Metin rengi */
  background-color: #4CAF50; /* Yeşil arka plan rengi */
}

/* "Siteye Dön" linkini formun içinde sağda şeffaf bir buton gibi görünmesi */
.siteye-don-link {
  display: inline-block;
  color: #007BFF; /* Bağlantının metin rengi */
  text-decoration: none; /* Bağlantı altını çizmez */
  margin-left: 10px; /* Buton ile arasında boşluk bırakır */
  padding: 10px 20px; /* Dolgu ekler */
  border: 1px solid #007BFF; /* Kenarlık ekler */
  border-radius: 5px; /* Yumuşak köşeler */
  background-color: transparent; /* Arkaplan şeffaf */
  cursor: pointer;
}

/* "Siteye Dön" linkine fare üzerine geldiğinde stil ekleyin */
.siteye-don-link:hover {
  background-color: #007BFF; /* Arkaplan rengini değiştirin */
  color: #fff; /* Metin rengini beyaza ayarlayın */
}

@media (max-width: 768px) {
  .form {
    width: 90%; /* Ekran daraldığında formun genişliğini artırın */
    max-width: none; /* Maksimum genişlik sınırlamasını kaldırın */
  }
}
